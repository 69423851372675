import './style.css';
import {Feature, Map, Overlay, View} from 'ol/index';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Cluster, OSM, Vector as VectorSource} from 'ol/source';
import {Point} from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import proj4 from 'proj4';
import SearchPhoton from 'ol-ext/control/SearchPhoton';
import {dsv} from 'd3-fetch';
import {Circle as CircleStyle, Fill, Icon, Stroke, Style, Text} from 'ol/style';
import {boundingExtent} from 'ol/extent';
import Permalink from 'ol-ext/control/Permalink';
import GeolocationButton from 'ol-ext/control/GeolocationButton';
import Popup from 'ol-ext/Overlay/Popup';
import Placemark from 'ol-ext/Overlay/Placemark';
import stationIconUrl from './data/icons8-fire-truck-66.png';
import hydrantIconUrl from './data/icons8-hydrant-64.png';

// load hydrant data
function utm2EPSG3857(point, zone) {
  const utm = "+proj=utm +zone=" + zone;
  const target = "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs";
  return proj4(utm, target, point);
}

const dataUrl = new URL('./data/hydrants.csv', import.meta.url).href;
const hydrantsData = await dsv(';', dataUrl, (d) => {
  return {
    hydrantId: d.HydrantenNr,
    long: +d.Long.replace(',','.'),
    lat: +d.Lat.replace(',','.'),
    plz: +d.PLZ,
    city: d.Ortsname,
    cityPart: d.Ortsteil,
    street: d.Strassenname,
    housenumber: d.HsNr
  }
});

const hydrantsFeatures = [];

hydrantsData.forEach((hydrant) => {
  const convertedPosition = utm2EPSG3857([hydrant.long, hydrant.lat], 32);
  const point = new Point(convertedPosition);
  const feature = new Feature(point);
  hydrantsFeatures.push(feature);
});

// initialize hydrants layer
const dataSource = new VectorSource({
  features: hydrantsFeatures,
});

const clusterDistance = 100;
const clusterMinDistance = 60;

const dataClusterSource = new Cluster({
  distance: clusterDistance,
  minDistance: clusterMinDistance,
  source: dataSource
});

const styleCache = {};
const clustered = new VectorLayer({
  source: dataClusterSource,
  style: function (feature) {
    const size = feature.get('features').length;
    let style = styleCache[size];
    if (!style) {
      if (size == 1) {
        style = new Style({
          /*image: new CircleStyle({
            radius: 10,
            fill: new Fill({
              color: '#6fa8dc',
            }),
          })*/
          image: new Icon({
            src: hydrantIconUrl,
            scale: 0.5,
          })
        });
      } else {
        style = new Style({
          image: new CircleStyle({
            radius: 15,
            stroke: new Stroke({
              color: '#fff',
            }),
            fill: new Fill({
              color: '#16537e',
            }),
          }),
          text: new Text({
            text: size.toString(),
            fill: new Fill({
              color: '#fff',
            }),
          }),
        });
      }
      styleCache[size] = style;
    }
    return style;
  }
});

// initialize map layer
const raster = new TileLayer({
  source: new OSM(),
});

// initialize base station layer
const place = fromLonLat([9.938340365006018, 53.57738667256053]);
const point = new Point(place);

const baseStation = new VectorLayer({
  source: new VectorSource({
    features: [new Feature(point)],
  }),
  style: new Style({
    /*image: new CircleStyle({
      radius: 20,
      fill: new Fill({
        color: 'red',
      })
    }),*/
    image: new Icon({
      //src: 'icons8-fire-station-30.png',
      src: stationIconUrl,
      scale: 1,
    })
  }),
});

// initialize map
const map = new Map({
  target: 'map',
  layers: [raster, baseStation, clustered],
  view: new View({
    center: place,
    zoom: 18,
    minZoom: 10
  })
});

map.on('moveend', (e) => {
  if (e.map.getView().getZoom() >= 17) {
    dataClusterSource.updateDistance(0, 0);
  } else {
    dataClusterSource.updateDistance(clusterDistance, clusterMinDistance);
  }
});

// initialize search placemark
const placemark = new Placemark({
  stopEvent: false
});
map.addOverlay(placemark);

// cluster handlers
map.on('click', (e) => {
  clustered.getFeatures(e.pixel).then((clickedFeatures) => {
    if (clickedFeatures.length) {
      // Get clustered Coordinates
      const features = clickedFeatures[0].get('features');
      if (features.length > 1) {
        const extent = boundingExtent(
          features.map((r) => r.getGeometry().getCoordinates())
        );
        map.getView().fit(extent, {duration: 1000, padding: [50, 50, 50, 50]});
      }
    }
  });
});

// Set the control grid reference
const search = new SearchPhoton({
  lang:"de",		// Force preferred language
  reverse: true,
  position: true	// Search, with priority to geo position
});
map.addControl(search);

// Select feature when click on the reference index
search.on('select', function(e) {
  placemark.show(e.coordinate);
  map.getView().animate({
    center: e.coordinate,
    zoom: Math.max(map.getView().getZoom(), 18)
  });
});

// threema permalink control
var threemaControl = new Permalink({
  className: 'ol-permalink threema',
  urlReplace: true,
  localStorage: false,	// Save permalink in localStorage if no url provided
  onclick: function(url) {
    //document.location = "mailto:?subject=subject&body="+encodeURIComponent(url);
    document.location = "threema://compose?text=" + encodeURIComponent(url);
  }
});
map.addControl(threemaControl);

// whatsapp permalink control
var whatsappControl = new Permalink({
  className: 'ol-permalink whatsapp',
  urlReplace: true,
  localStorage: false,	// Save permalink in localStorage if no url provided
  onclick: function(url) {
    //document.location = "mailto:?subject=subject&body="+encodeURIComponent(url);
    document.location = "whatsapp://send?text=" + encodeURIComponent(url);
  }
});
map.addControl(whatsappControl);

// geolocation control
var geoloc = new GeolocationButton({
  title: 'Standort suchen',
  delay: 2000, // 2s
  zoom: 18,
});
map.addControl(geoloc);

// Show position
var here = new Popup({ positioning: 'bottom-center' });
map.addOverlay(here);
geoloc.on('position', function(e) {
  placemark.hide();
  if (e.coordinate) here.show(e.coordinate, "Du bist <br/>hier!");
  else here.hide();
});